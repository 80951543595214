import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { Billboard, Image, Plane, Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';

import TextWithRoundedBackground from './TextWithBackground';

const NameTag = (props) => {
  const { text, position } = props;
  const tagRef = useRef();
  const boardRef = useRef();
  const textRef = useRef();
  const billboardRef = useRef();
  const face = useMemo(() => new THREE.Vector3(0, 1, 0), []);

  useEffect(() => {
    if (position && tagRef.current && face) {
      tagRef.current.position.set(0, 0, 0);
      tagRef.current.lookAt(face);
      tagRef.current.position.set(position.x, 2.35, position.z);
    }
  }, [position, face]);

  useFrame(() => {
    if (tagRef.current && boardRef.current) {
      boardRef.current.position.set(0, 0, 0);
      boardRef.current.lookAt(tagRef.current.position);
      boardRef.current.position.set(0, 0.3, 0);
    }
  });

  return (
    <group ref={tagRef} visible={true} renderOrder={2}>
      <group ref={boardRef} scale={0.065}>
        <Billboard ref={billboardRef} follow={true} position={[0, 0, 0]}>
          <LocationIconWithText text={text} />
          {/* {bgSize && <RoundedShape size={bgSize} color='#7B61FF' />} */}
        </Billboard>
      </group>
    </group>
  );
};

function LocationIconWithText({ text }) {
  return (
    <group scale={3.5}>
      <Text
        font={'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff'}
        text={'place'}
        fontSize={1.1}
        anchorX="center"
        anchorY="middle"
        color={'red'}
        renderOrder={3}
        position={[0, 0, 0]}
        scale={1}
      />
      {/* <Text
        position={[0, 0.75, 0]}
        fontSize={0.5}
        anchorX="center"
        anchorY="bottom"
        color={'#ffffff'}
        renderOrder={3}
        material-toneMapped={false}
        maxWidth={10}
        ref={textRef}
      >
        {text}
      </Text> */}
      {/* <group position={[0, 1, 0]}>
        <Plane args={planeSize} material-color={'red'}>
          <Text
            position={[0, -0.25, 0]}
            fontSize={0.5}
            anchorX="center"
            anchorY="bottom"
            color={'#ffffff'}
            renderOrder={3}
            material-toneMapped={false}
            maxWidth={10}
            ref={textRef}
          >
            {text}
          </Text>
        </Plane>
      </group> */}

      <TextWithRoundedBackground
        text={text}
        position={[0, 1, 0]}
        rotation={[0, 0, 0]}
        fontSize={0.5}
        color="white"
        backgroundColor="red"
        padding={0.1}
        cornerRadius={0.08}
      />
    </group>
  );
}

export default NameTag;
