import React, { useEffect, useRef, useState } from 'react';

import { Text } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

import RoundedPlane from './RoundedPlane';

export default function TextWithRoundedBackground({
  text,
  position,
  rotation,
  fontSize,
  color,
  backgroundColor,
  padding = 0.2,
  cornerRadius = 0.05,
}) {
  const textRef = useRef();
  const [planeSize, setPlaneSize] = useState([1, 1]);
  const { size } = useThree();

  useEffect(() => {
    if (textRef.current) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = `${fontSize * size.height}px Arial`;
      const metrics = context.measureText(text);
      const width = metrics.width / size.height + padding * 2;
      const height = fontSize + padding * 2;
      setPlaneSize([width, height]);
    }
  }, [text, fontSize, size, padding]);

  return (
    <group position={position} rotation={rotation} renderOrder={2}>
      <RoundedPlane
        width={planeSize[0]}
        height={planeSize[1]}
        radius={cornerRadius}
        color={backgroundColor}
      />
      <Text
        ref={textRef}
        fontSize={fontSize}
        color={color}
        anchorX="center"
        anchorY="middle"
        position={[0, 0, 0.02]}
      >
        {text}
      </Text>
    </group>
  );
}
