const nameTags = [
  {
    id: '01',
    text: 'F & C Event: Haven Program',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -13.338980211827229, y: 0.8861856060803266, z: 8.428822203964263 },
  },
  {
    id: '02',
    text: 'Shop 1 & 2: Wide Leg & All Day',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -15.010731751790892, y: 0.3076128806693752, z: 12.73293057852724 },
  },
  {
    id: '03',
    text: 'Shop 3 & 4: Denim Shorts',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -13.71101283951451, y: 0.2341055382820787, z: 21.785002011770313 },
  },
  {
    id: '04',
    text: 'Shop 9 & 10: Footwear & Accessories',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -16.152735451377293, y: 1.2960186702709713, z: 27.60528895241109 },
  },
  {
    id: '05',
    text: 'Shop 5: Bella & Graphics',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -12.75682550496527, y: 0.005828232118889609, z: 29.072619072058295 },
  },
  {
    id: '06',
    text: 'Shop 6: Denim',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -10.57568449656739, y: 0.5242713388133056, z: 27.332979303152445 },
  },
  {
    id: '07',
    text: 'Shop 7 & 8: Beach',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -5.68844428740495, y: 0.5222819375462764, z: 26.723173217900783 },
  },
  {
    id: '08',
    text: 'Shop 1 & 2: Tights & Crops',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 4.932518469384254, y: -0.008214482983552429, z: 27.67558754527647 },
  },
  {
    id: '09',
    text: 'Shop 3 & 4: Active Shorts',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 11.658130026860853, y: 0.012532310846028238, z: 29.237761828799073 },
  },
  {
    id: '10',
    text: 'Shop 7: Fashion Bras & Undies',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 10.41038053327501, y: 0.9821837989628388, z: 23.12544150896575 },
  },
  {
    id: '11',
    text: 'Shop 5 & 6: Sleep Separates',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 7.2969531262410445, y: 0.49756638847294665, z: 23.03230937594096 },
  },
  {
    id: '12',
    text: 'F & C Event: Health & Wellness Hoodies',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 1.9287280887231115, y: 0.9818503893772309, z: 22.524425518123113 },
  },
  {
    id: '13',
    text: 'CZ 2: Baggy',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -10.646648889357618, y: 0.013827629387378693, z: 17.841646451542594 },
  },
  {
    id: '14',
    text: 'CZ 1: Suiting',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -5.751540452361012, y: 0.012353038610729561, z: 13.908367913662257 },
  },
  {
    id: '15',
    text: 'Shop 1 & 2: Baggy Shorts & Shirts',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -7.165745480986949, y: 0.5153790765256985, z: 6.164375936974051 },
  },
  {
    id: '16',
    text: 'Shop 3: Linen & Beach',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 0.8338151098957368, y: 0.4987491550101586, z: 5.615353271353995 },
  },
  {
    id: '17',
    text: 'Shop 4: Graphics',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 4.8833680318632116, y: 0.9603533908279042, z: 6.121398720797695 },
  },
  {
    id: '18',
    text: 'Shop 5: Denim',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 3.5614052290079803, y: 0.015601858421559367, z: 9.869074226840645 },
  },
  {
    id: '19',
    text: 'Dwell',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 3.952419802825954, y: 1.0263527472433887, z: 15.142383779226334 },
  },
  {
    id: '20',
    text: 'CZ 3: Boho Dresses & Tops',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -6.542839427089302, y: 0.027816543851044484, z: 21.883440870858713 },
  },
];

export default nameTags;
