const store = {
  id: 'watergardens',
  name: 'COTTON ON - Watergardens',
  model:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/store-models/cottonon-watergardens.glb',
  modelId: 'f20de22508cf4ff5826abdd49be6b00d',
  storeFrontId: 'd45212e30dc94679af50d92aaa08a161',
  storeLogo:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/assets/cotton-on-logo.svg',
  storeWelcomeText: 'Welcome to the Cotton On Watergardens Experience.',
  storeFavIcon:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/assets/cotton-on.ico',

  cameraRotation: { azimuthal: -122, polar: 90 },
  rotationDegrees: 178,
  menus: [
    {
      title: 'Entrance',
      locationId: 'd45212e30dc94679af50d92aaa08a161',
      options: {
        rotation: {
          azimuthal: -2.124210813072263,
          polar: 1.5535821204738571,
        },
      },
    },
    {
      title: 'Womens',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
      subMenus: [
        {
          title: 'F & C Event: Haven Program',
          locationId: 'a5ff1f2c86a24ef08ef36ee447cd115c',
          options: {
            rotation: { azimuthal: -2.131172530782758, polar: 1.5726671701445725 },
          },
        },
        {
          title: 'Shop 1 & 2: Wide Leg & All Day',
          locationId: 'c7a2f6935ac34f559106efa141507a9e',
          options: {
            rotation: { azimuthal: -4.267006067601, polar: 1.4695623023674396 },
          },
        },
        {
          title: 'Shop 3 & 4: Denim Shorts',
          locationId: 'f830033232454469ae97fcc182aefef6',
          options: {
            rotation: { azimuthal: 2.447677020778183, polar: 1.5282104238125227 },
          },
        },
        {
          title: 'Shop 5: Bella & Graphics',
          locationId: '9fd5b144ed52470f9050c85f8bc7874e',
          options: {
            rotation: { azimuthal: 3.047936342898053, polar: 1.6284074867949105 },
          },
        },
        {
          title: 'Shop 6: Denim',
          locationId: '44d46765f09b47ee87907a6d54c23bea',
          options: {
            rotation: { azimuthal: 3.1229175803314, polar: 1.6271906835579288 },
          },
        },
        {
          title: 'Shop 7 & 8: Beach',
          locationId: 'a97aff7560694925adc8200d0fc021bc',
          options: {
            rotation: { azimuthal: 3.6114189071801803, polar: 1.6130663170204922 },
          },
        },
        {
          title: 'Shop 9 & 10: Footwear & Accessories',
          locationId: '8ad48f03022246f6ab2458ea4524c3a9',
          options: {
            rotation: { azimuthal: 1.1136297727787245, polar: 1.5456829591155132 },
          },
        },
        {
          title: 'CZ 1: Suiting',
          locationId: '79c6299556ce4ecabf1e8c5603dc5bd6',
          options: {
            rotation: { azimuthal: -2.294756668227563, polar: 1.4044518836376163 },
          },
        },
        {
          title: 'CZ 2: Baggy',
          locationId: 'd53719aaf9bc41579f427881864c6971',
          options: {
            rotation: { azimuthal: -2.2659600103982958, polar: 1.4635325264958874 },
          },
        },
        {
          title: 'CZ 3: Boho - Dresses & Tops',
          locationId: '48ad219bec224aca87b572e3b2a8a389',
          options: {
            rotation: { azimuthal: -7.333093501108334, polar: 1.5571105808548655 },
          },
        },
        {
          title: 'Sale',
          locationId: '26275c3c31ce492fa5a12b3ca1ae7e67',
          options: {
            rotation: { azimuthal: -1.031136588986165, polar: 1.0019488621590797 },
          },
        },
      ],
    },
    {
      title: 'Menswear',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
      subMenus: [
        {
          title: 'Shop 1 & 2: Baggy Shorts & Shirts',
          locationId: 'bd738b8e8b0d4f49a79710732767c3de',
          options: {
            rotation: { azimuthal: -0.6676721776680777, polar: 1.4820759008242894 },
          },
        },
        {
          title: 'Shop 3: Linen & Beach',
          locationId: '0d271fd8d27b40929dee75533969befb',
          options: {
            rotation: { azimuthal: 4.227452467540107, polar: 1.6267335046882585 },
          },
        },
        {
          title: 'Shop 4: Graphics',
          locationId: '1d94a35b057242f397853bde61f0e58d',
          options: {
            rotation: { azimuthal: 4.915764730262076, polar: 1.6734368113887899 },
          },
        },
        {
          title: 'Shop 5: Denim',
          locationId: 'e5254c8fcdef4733a7c265df9ab45974',
          options: {
            rotation: { azimuthal: 4.235169929815176, polar: 1.7298753919694574 },
          },
        },
      ],
    },
    {
      title: 'Body',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
      subMenus: [
        {
          title: 'F & C Event: Health & Wellness Hoodies',
          locationId: 'bdfa4b3e93b047b0b95d0999890b63b5',
          options: {
            rotation: { azimuthal: 3.7848693799218776, polar: 1.4326750732912295 },
          },
        },
        {
          title: 'Shop 1 & 2: Tights & Crops',
          locationId: 'dead8c2374f045f9836acf7d8e1581bc',
          options: {
            rotation: { azimuthal: 2.6122575514857274, polar: 1.5537819633523857 },
          },
        },
        {
          title: 'Shop 3 & 4: Active Shorts',
          locationId: '6646b5b6f0e049919a19b3643f072124',
          options: {
            rotation: { azimuthal: 4.507901322996144, polar: 1.5083454513752128 },
          },
        },
        {
          title: 'Shop 7: Fashion Bras & Undies',
          locationId: 'c4d6dbfca4634554ad3eb6c42e4bd62d',
          options: {
            rotation: { azimuthal: 5.537636840809773, polar: 1.5592928496672946 },
          },
        },
      ],
    },
  ],
  panoImageBaseUrl:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/location-images-tiled-2k/',
    baseUrl4k:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/location-images-tiled-4k/',
    baseUrl2k:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/location-images-tiled-2k/',
  },
  layoutSettings: {
    modalAlignment: 'center',
  },
};

export default store;
