import React from 'react';
import * as THREE from 'three';

export default function RoundedPlane({ width, height, radius, color }) {
  const shape = new THREE.Shape();
  const x = -width / 2;
  const y = -height / 2;

  shape.moveTo(x, y + radius);
  shape.lineTo(x, y + height - radius);
  shape.quadraticCurveTo(x, y + height, x + radius, y + height);
  shape.lineTo(x + width - radius, y + height);
  shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
  shape.lineTo(x + width, y + radius);
  shape.quadraticCurveTo(x + width, y, x + width - radius, y);
  shape.lineTo(x + radius, y);
  shape.quadraticCurveTo(x, y, x, y + radius);

  const geometry = new THREE.ExtrudeGeometry(shape, {
    depth: 0.01,
    bevelEnabled: false,
  });

  return (
    <mesh geometry={geometry}>
      <meshBasicMaterial color={color} opacity={1} transparent />
    </mesh>
  );
}
