const tags = [
  {
    id: '01',
    type: 'video',
    face: { x: 0.3560924274912573, y: 0.9320941513930805, z: -0.0663225151980594 },
    position: { x: -14.845372296804083, y: 0.8759786135311041, z: 14.302798197288128 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/videos/1.%20PILE%20HEIGHTS.mp4',
    },
  },
  {
    id: '02',
    type: 'video',
    face: { x: 0.05306791217438671, y: 0.004521639106455855, z: -0.9985806684876505 },
    position: { x: -13.821252332103448, y: 1.748294975905113, z: 29.784918223582345 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/videos/2.%20GRAPHICS.mp4',
    },
  },
  {
    id: '03',
    type: 'video',
    face: { x: -0.9998517970714295, y: -0.0018475358498517933, z: -0.017116381168820548 },
    position: { x: 2.4780832501341945, y: 1.2950480621516622, z: 22.41883081718933 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/videos/3.%20BRAND%20ADJACENCIES.mp4',
    },
  },
  {
    id: '04',
    type: 'video',
    face: { x: 0.015081213720199105, y: 0.8901143547946475, z: 0.4554876424023316 },
    position: { x: -4.572859716177329, y: 0.9473351488199501, z: 18.01936956609882 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-watergardens/default/videos/4.%20SALE.mp4',
    },
  },
];

export default tags;
