import React, { useEffect, useState } from 'react';

import mockNameTags from '../../../../apis/data/watergardens/name-tags';
import NameTag from './NameTag';

const NameTags = () => {
  const [nameTags, setNameTags] = useState();

  useEffect(() => {
    if (process.env.REACT_APP_SPACE_ALIAS == 'watergardens') {
      setNameTags(mockNameTags);
    }
  }, []);

  return (
    <group>
      {nameTags &&
        nameTags.map((item) => <NameTag key={item.id} text={item.text} position={item.position} />)}
    </group>
  );
};

export default NameTags;
